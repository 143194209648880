import React from "react"
import styled from "styled-components"
import { Text } from "../core/commonExports"

import CircleChecked from "../../assets/images/circle-checked.svg"
import CircleUnchecked from "../../assets/images/circle-unchecked.svg"
import BusinessProperty from "../../assets/images/lead-form-icons/business-property.svg"
import BusinessLiability from "../../assets/images/lead-form-icons/business-liability.svg"


const ShowItemSelection = ({selected = false}) => {
  if (selected) {
      return <CircleChecked />
  } else {
      return <CircleUnchecked />
  }
}

const PolicyTypeSelection = ({onOptionSelect, selectedOption}) => {
  return (
    <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
      <Text style={{alignSelf: "center"}} fontSize="18px" mfontSize="16px" lineHeight="22.68px" color="#8668AD" fontWeight="bold">
        What do you want to secure?
      </Text>
      <RadioSelectionButton style={{marginTop: "1rem"}} onClick={() => onOptionSelect("business-property")} selected={selectedOption === "business-property"}>
        <ShowItemSelection selected={selectedOption === "business-property"} />
        <div style={{display: "flex", alignItems: "center", gap: "1rem"}}>
          <BusinessProperty />
          <RadioSelectionTextContainer>
            <Text fontSize="18px" mfontSize="14px" lineHeight="22px" fontWeight="bold" color="#000">Business Property</Text>
            <Text fontSize="16px" mfontSize="12px" lineHeight="22px">Protect buildings, stock and machines from fire, flood, theft, riots, strikes, and 10 other threats</Text>
          </RadioSelectionTextContainer>
        </div>
      </RadioSelectionButton>
      <RadioSelectionButton style={{marginTop: "1rem"}} onClick={() =>  onOptionSelect("business-liability")} selected={selectedOption === "business-liability"}>
        <ShowItemSelection selected={selectedOption === "business-liability"} />
        <div style={{display: "flex", alignItems: "center", gap: "1rem"}}>
          <BusinessLiability />
          <RadioSelectionTextContainer>
            <Text fontSize="18px" mfontSize="14px" lineHeight="22px" fontWeight="bold" color="#000">Business Liability</Text>
            <Text fontSize="16px" mfontSize="12px" lineHeight="22px">Get liability insurance for Directors & Officers, Errors & Omissions, Public Liability and Workmen’s Compensation</Text>
          </RadioSelectionTextContainer>
        </div>
      </RadioSelectionButton>
    </div>
  )
}

const RadioSelectionButton = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    width: 100%;
    min-height: 34px;
    font-size: 14px;
    background: #fff;
    border: 1px solid ${p => p.selected ? "rgba(255, 147, 79, 1)" : "#CCCCCC"};
    color: ${p => p.selected ? "rgba(255, 147, 79, 1)" : "rgba(51, 51, 51, 1)"};
    border-radius: 8px;
    z-index: 9999;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
`

const RadioSelectionTextContainer = styled.div`
  max-width: 295px; 
  text-align: left;
  @media screen and (max-width: 768px) {
    max-width: 200px;
  }
`

export default PolicyTypeSelection